import { getIdentifier } from 'react-commons';
import Cookies, { CookieAttributes } from 'js-cookie';
import { action, createStoreContext, CreateStoreOptions } from '@twocatmoon/react-actions';
import { captureEvent } from '@/lib/util/captureEvent';
import { useRouter } from 'next/router';
import UsersModel from '@/lib/drupal/models/Users';
import { authActions, useAuthStore } from '@/lib/drupal/stores/auth';
import { loadingPanelEvents } from '@/components/LoadingPanel';

const OLD_COOKIE_ATTRS: CookieAttributes = { 
  path: '/', 
  secure: true,
  sameSite: 'none',
  expires: 365,
};

const COOKIE_ATTRS: CookieAttributes = { 
  path: '/', 
  secure: true,
  sameSite: 'lax',
  expires: 365,
  domain: '.shockwave.com'
};

// #region Schema & State

export interface ThemeState {
  theme: 'default' | 'contrast' | 'modern' | 'modernDark'
  layout?: 'classic' | 'modern'
}

const initialState: ThemeState = {
  theme: 'default'
};

// Necessary if some components (e.g. error pages) will be shown under other routes
// example: shockwave.com/members/profile/nonExistentUser
export const modernOnlyComponents = [
  'NotFoundPage'
];

export const classicOnlyComponents = [

];

export const modernOnlyRoutes = [
  '/search/[[...params]]',
  '/online/[term]',
  '/download/[term]',
  '/account/login',
  '/account/register',
  '/purchase/subscribe',
  '/gamelanding/[slug]/archive',
  '/account/user/edit',
  '/account/user/edit/subscription',
  '/account/user/edit/subscription/manage',
  '/account/user/edit/personal',
  '/account/user/edit/picture',
  '/account/user/purchases',
  '/account/user/email-verification',

  '/about',
  '/about/contact',
  '/about/privacyPolicy',
  '/about/[topic]',
  
  '/help',
  '/help/[topic]',
  '/help/faq',
  '/help/faq/[topic]',
  '/help/resend-keys',

  '/welcome-to-shockwave',

  '/404',
];

export const classicOnlyRoutes = [
  '/online/all-games',
  '/online/all-genres',
  '/download/all-games',
  '/download/all-genres',
  '/gameplayer/[slug]',
  '/500',
  '/site-search',
];

// #endregion



// #region Actions

export const themeActions = {
  setTheme: action<ThemeState, ThemeState['theme']>((prevState, theme) => {
    theme = theme || 'default';
    const layout = prevState.layout || 'classic';

    if (layout === 'modern') {
      if (theme === 'default' || theme === 'contrast') {
        theme = 'modern';
      }
    } else {
      if (theme === 'modern' || theme === 'modernDark') {
        theme = 'default';
      }
    }

    Cookies.remove('sw_result_layout', OLD_COOKIE_ATTRS);
    Cookies.remove('sw_theme', OLD_COOKIE_ATTRS);
    Cookies.set('sw_result_layout', layout, COOKIE_ATTRS);
    Cookies.set('sw_theme', theme, COOKIE_ATTRS);

    captureEvent('user_preferences', { 
      tag_name: 'user_preferences',
      theme,
      layout,
    });

    return {
      ...prevState,
      theme,
      layout,
    };
  }),

  setLayout: action<ThemeState, ThemeState['layout']>((prevState, layout) => {
    let theme = prevState.theme || 'default';

    if (layout === 'modern') {
      if (theme === 'default' || theme === 'contrast') {
        theme = 'modern';
      }
    } else {
      if (theme === 'modern' || theme === 'modernDark') {
        theme = 'default';
      }
    }
    
    Cookies.remove('sw_result_layout', OLD_COOKIE_ATTRS);
    Cookies.remove('sw_theme', OLD_COOKIE_ATTRS);
    Cookies.set('sw_result_layout', layout, COOKIE_ATTRS);
    Cookies.set('sw_theme', theme, COOKIE_ATTRS);

    captureEvent('user_preferences', { 
      tag_name: 'user_preferences',
      theme,
      layout,
    });

    return {
      ...prevState,
      theme,
      layout,
    };
  })
};

// #endregion



// #region Export

const options: CreateStoreOptions = {
  storageKey: getIdentifier('theme'),
  storageType: 'local',
  ssr: true,
};

export const { 
  Provider: ThemeStoreProvider, 
  useStore: useThemeStore, 
} = createStoreContext<ThemeState>(initialState, themeActions, options);

export function useSwitchLayout () {
  const router = useRouter();
  const [ authState, authDispatch ] = useAuthStore();
  const [ _themeState, themeDispatch ] = useThemeStore();

  return async (layout: ThemeState['layout']) => {
    const shouldReload = router.asPath === '/' || router.asPath.includes('gamelanding');

    if (shouldReload) loadingPanelEvents.trigger('show');

    if (authState.user) {
      try {
        await UsersModel.updateLayoutPreference(authState.user.uid, layout);
        authDispatch(authActions.setLayoutPreference(layout));
      } catch {}
    }

    themeDispatch(themeActions.setLayout(layout));

    if (shouldReload) window.location.reload();
  };
}

// #endregion
